﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { ContentRequestDto, VideoQueueDto, VideoDto } from "./content.models";

export interface IErrorService {

}

@Injectable()
export class ErrorService implements IErrorService {
    apiBaseUri: string;



    constructor(private http: HttpClient) {
        this.apiBaseUri = "/api/v1/";
        this.http = http;
    }

    public set(request: string): Observable<boolean> {
        var url: string = this.apiBaseUri + "error/" + request ;
        
        return this.http.get<boolean>(url);
    }


    
}