﻿
import { Component, Input } from '@angular/core';
import "!svg-sprite-loader!element.ui/modules/assets/icons/material-design/perm_contact_calendar.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/material-design/person.svg";

@Component({
    selector: 'header-component',
    templateUrl: './header.component.html',
})

export class HeaderComponent {
    @Input() application: string;
}