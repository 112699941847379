﻿import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    template: `
<router-outlet></router-outlet>
<footer-component></footer-component>
`
})

export class LayoutComponent {
}