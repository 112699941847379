﻿import { Component, OnInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ContentService } from "../app/content.service";

import { JQ_TOKEN } from "./common/index";
import { Router } from '@angular/router';
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/facebook-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/pinterest-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/twitter-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/youtube-square.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/font-awesome/instagram.svg";
import { ContentFooterService } from './content.footer.service';
declare var mayojs: any;




@Component({
	selector: 'footer-component',
	templateUrl: './footer.component.html',
	providers: [DatePipe]
})

export class FooterComponent implements  OnInit {
	year: string;
	datePipe: DatePipe;
	branded: boolean;
	url: string;
	isGNTContent: boolean;
	gntFooter: string;

	constructor(private _datePipe: DatePipe,
		private router: Router,
		private service: ContentService,
		private contentFooterService: ContentFooterService,
		@Inject(JQ_TOKEN) private $: any) {
		this.datePipe = _datePipe;
		this.branded = window.location.host.indexOf("live.myhealthcontent") === -1;
		if (this.branded) {
			this.$("link[rel='shortcut icon']").attr('href', "https://myhealthcontent.mayoclinic.org/src/favicon.ico");
		}
		this.url = window.location.href;
	}

	ngOnInit(): void {
		var now = mayojs.getDate();
		this.year = this.datePipe.transform(now, "yyyy");
		this.contentFooterService.gntContentFlag.subscribe(data => {
			this.isGNTContent = data;
		});
		this.contentFooterService.gntContentFooter.subscribe(data => {
			this.gntFooter = data;
			this.gntFooter = this.gntFooter.replace("<ul>", "<p>").replace("</ul>", "</p>").replace("<li>", "<span>").replace("</li>", "</span>");
		});
	}
}