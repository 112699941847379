﻿export class ContentRequestDto {
    public ContentId: string;
    public Lang: string;
    public state: string;
    public ApiKey: string;
    public SofAppKey: string;
}

export class VideoQueueDto {
    public kalturaVideo: VideoDto;
    public mediaPath: string;
    public downloadUrl: string;
}

export class VideoDto {
    public videoId: string;
    public dataAccount: string;
}

export class TokenResponseDto {
    public state: string;
}

export class StateDto {
    public client: ClientDto;
}

export class ClientDto {
    public client_id: string;
}