﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface IUtilityService {

}

@Injectable()
export class UtilityService implements IUtilityService {
    apiBaseUri: string;

    constructor(private http: HttpClient) {
        this.apiBaseUri = "/api/v1/";
        this.http = http;
    }

    public environment(): Observable<string> {
        var url: string = "";
        url = this.apiBaseUri + "utility/environment";

        return this.http.get(url, { responseType: 'text' });
    }

}