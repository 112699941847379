﻿import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LayoutComponent } from "./layout.component";
import { SharedModule } from '../shared/shared.module'
import { HomeComponent } from './home.component'
import { ContentComponent } from './content.component'
import { ErrorComponent } from './error.component'
import { ErrorService } from './error.service'
import { ContentService } from './content.service'
import { JQ_TOKEN, TOASTR_TOKEN, Toastr, SimpleModalComponent, NoSanitizePipe } from '../shared/common/index';
import { ModalTriggerDirective } from '../shared/common/modalTrigger.directive';
let toastr: Toastr = window['toastr'];
let jQuery = window['$'];
import { ContentFooterService } from "../shared/content.footer.service";
import { UtilityService } from "./utility.service";
import { PrintComponent } from "./print.component";

const routes: Routes = [
    { path: '', redirectTo: 'error/404', pathMatch: 'full'},
    { path: 'content/:contentId/:lang/:clientId', component: ContentComponent },
    { path: 'content/:contentId/:lang', component: ContentComponent },
    { path: 'content/:contentId', component: ContentComponent },
    { path: 'error/:error', component: ErrorComponent },
    { path: '**', redirectTo: 'error/404', pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        HttpClientModule,
        FormsModule,
        SharedModule],
    declarations: [
        LayoutComponent,
        HomeComponent,
        ContentComponent,
        ErrorComponent,
        SimpleModalComponent,
        NoSanitizePipe,
        ModalTriggerDirective,
        PrintComponent],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        Title,
        ContentService,
        UtilityService,
        ErrorService,
        ContentFooterService,
        { provide: TOASTR_TOKEN, useValue: toastr },
        { provide: JQ_TOKEN, useValue: jQuery }],
    bootstrap: [LayoutComponent]
})
export class AppModule {
}