﻿import { Component, AfterViewInit, Inject, OnInit, Input  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { switchMap } from "rxjs/operators"
import { DomSanitizer } from "@angular/platform-browser";
import { ErrorService } from "./error.service";
import { JQ_TOKEN, TOASTR_TOKEN, Toastr } from "../shared/common/index";
declare var mayojs: any;

@Component({
    selector: 'error-component',
    templateUrl: './error.component.html',
        providers: [DatePipe]
})

export class ErrorComponent implements AfterViewInit, OnInit {

    public errorText: string;
    public messages: string[];
    public loading: boolean;
    time: string;

    public constructor(
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private service: ErrorService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr,
        @Inject(JQ_TOKEN) private $: any) {
    }


    ngOnInit() {
        this.loading = false;
        var error = null;

        var now = mayojs.getDate();
        this.time = this.datePipe.transform(now, 'MM/dd/yyyy h:mm a ZZZZ');

        this.route.paramMap.subscribe(params => {

        if (this.route.snapshot === undefined ||
            this.route.snapshot === null ||
            this.route.snapshot.queryParams === undefined ||
            this.route.snapshot.queryParams === null) {
            error = "400";
        } else {
            error = params.get("error");
        }
            //API call that throws an error
            this.service.set(error).subscribe(a => { },
                err => {
                    this.errorText = err.error.ExceptionMessage || err.error.ResponseMessage ||  err.error.Message;
                    if (this.errorText) {
                        this.messages = this.errorText.split("\n");
                    }
                    this.loading = false;
                }
            );

           //mayojs.loadAnalyticScript();
        });
    }

    ngAfterViewInit(): void {
        mayojs.init();
    }
}