﻿import { Component, AfterViewInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { JQ_TOKEN, TOASTR_TOKEN, Toastr } from "../shared/common/index";
import '../assets/scripts/custom.js';
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/brain-health.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/person-success.svg";
import "!svg-sprite-loader!element.ui/modules/assets/icons/mayo/family-history.svg";
declare var mayojs: any;

@Component({
    selector: 'home-component',
    templateUrl: './home.component.html'
})

export class HomeComponent implements AfterViewInit {

    public constructor(private titleService: Title, private router: Router,
        @Inject(TOASTR_TOKEN) private toastr: Toastr, @Inject(JQ_TOKEN) private $: any) {
        titleService.setTitle("APPLICATION TITLE");
    }

    ngAfterViewInit(): void {
        this.$("#btnAlert").click(() => {
            alert("Event attached in Home Component using JQuery");
        });
        this.$("#btnToastr").click(() => {
            this.toastr.success("THIS IS A SUCCESS MESSAGE");
            this.toastr.error("THIS IS AN ERROR MESSAGE");
            this.toastr.info("THIS IS AN INFO MESSAGE");
            this.toastr.warning("THIS IS A WARNING MESSAGE");
        });
        mayojs.init();
    }
}