﻿import { Component, AfterViewInit, Inject, OnInit, Input } from '@angular/core';
declare var mayojs: any;

@Component({
    selector: 'print-component',
    templateUrl: './print.component.html'
})

export class PrintComponent implements AfterViewInit, OnInit  {

    private displayButton: boolean;
    constructor() {

    }
    ngOnInit(): void {
        this.displayButton = true;
    }

    ngAfterViewInit(): void {
        mayojs.init();
    }

    onPrint(): void {
        mayojs.doPrint();
    }
}