﻿import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()

export class ContentFooterService {
    private isGNTContent = new BehaviorSubject<boolean>(false);
    gntContentFlag = this.isGNTContent.asObservable()

    private gntFooter = new BehaviorSubject<string>('');
    gntContentFooter = this.gntFooter.asObservable()

    constructor() { }
    setIsGNTContent(isGNTContent: boolean) {
        this.isGNTContent.next(isGNTContent);
    }

    setGNTFooter(footer: string) {
        this.gntFooter.next(footer);
    }
}