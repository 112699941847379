﻿import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { ContentRequestDto, VideoQueueDto, VideoDto } from "./content.models";

export interface IContentService {

}

@Injectable()
export class ContentService implements IContentService {
    apiBaseUri: string;



    constructor(private http: HttpClient) {
        this.apiBaseUri = "/api/v1/";
        this.http = http;
    }

    public get(request: ContentRequestDto): Observable<string> {
        var url: string = "";
        if (request.SofAppKey !== undefined &&
            request.SofAppKey !== null &&
            request.SofAppKey.length > 0) {
            url = this.apiBaseUri + "content/sof/" + request.ContentId + "/" + request.Lang + "/" + request.SofAppKey;
        }
        else {
            url = this.apiBaseUri + "content/" + request.ContentId + "/" + request.Lang + "/" + request.ApiKey;
        }
        return this.http.get(url, { responseType: 'text' });
    }

    public video(request: ContentRequestDto): Observable<VideoQueueDto> {
        var url: string = "";
        if (request.SofAppKey !== undefined &&
            request.SofAppKey !== null &&
            request.SofAppKey.length > 0) {
            url = this.apiBaseUri + "video/sof/" + request.ContentId + "/" + request.Lang + "/" + request.SofAppKey;
        }
        else {
            url = this.apiBaseUri + "video/" + request.ContentId + "/" + request.Lang + "/" + request.ApiKey;
        }
        return this.http.get<VideoQueueDto>(url);
    }

    public getContentJson(request: ContentRequestDto): Observable<string> {
        var url: string = "";
        url = this.apiBaseUri + "content/json/" + request.ContentId + "/" + request.Lang + "/" + request.ApiKey;

        return this.http.get(url, { responseType: 'text' });
    }
}