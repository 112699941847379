﻿import { Component, AfterViewInit, Inject, OnInit, Input  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { switchMap } from "rxjs/operators"
import { DomSanitizer } from "@angular/platform-browser";
import { JQ_TOKEN, TOASTR_TOKEN, Toastr } from "../shared/common/index";
import { ContentService } from "./content.service";
import { ContentRequestDto, StateDto, TokenResponseDto } from "./content.models";
import "../assets/scripts/fhir-client.js";
import { ContentFooterService } from '../shared/content.footer.service';
import { environment } from '../environments/environment';
import { UtilityService } from './utility.service';
declare var mayojs: any;
declare var FHIR: any;

@Component({
    selector: 'content-component',
    templateUrl: './content.component.html'
})

export class ContentComponent implements AfterViewInit, OnInit {

    private showLoading: boolean;
    private result: string;
    private isInteractive: boolean = true;
    private isProd: boolean;
    public loading: boolean;
    public hasError: boolean;
    public errorText: string;
    private request: ContentRequestDto;
    public isGNTContent: boolean;
    public name:string

    public constructor(
        private titleService: Title,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private service: ContentService,
        private utilService: UtilityService,
        private contentFooterService: ContentFooterService,
        @Inject(TOASTR_TOKEN) private toastr: Toastr,
        @Inject(JQ_TOKEN) private $: any) {
        this.showLoading = true;
        this.request = new ContentRequestDto();
        this.result = "";
        this.loading = true;
    }

    ngOnInit() {
        if (this.route.snapshot === undefined ||
            this.route.snapshot === null ||
            this.route.snapshot.queryParams === undefined ||
            this.route.snapshot.queryParams === null) {
            window.location.href = window.location.origin + "/error/404";
            return;
        }

        this.route.paramMap.subscribe(params => {
            this.request.ContentId = params.get("contentId");
            this.request.Lang = params.get("lang");
        });

        var apikey = this.route.snapshot.queryParams["apikey"];

        if (apikey !== undefined &&
            apikey !== null &&
            apikey.length > 0) {
            // this is standard oauth client
            this.request.ApiKey = apikey;
            this.request.SofAppKey = null;
        }
        else {
            var tokenResponseSessionStorage = sessionStorage["tokenResponse"];
            if (tokenResponseSessionStorage === undefined ||
                tokenResponseSessionStorage === null ||
                tokenResponseSessionStorage.length === 0) {
                window.location.href = window.location.origin + "/error/403";
                return;
            }
            // this is FHIR client
            var tokenResponseDto: TokenResponseDto = JSON.parse(tokenResponseSessionStorage + "");
            var stateDto: StateDto = JSON.parse(sessionStorage[tokenResponseDto.state]);
            this.request.SofAppKey = stateDto.client.client_id;
            //sessionStorage.removeItem(tokenResponseDto.state);        //Discuss: If I keep this line of code, "Back button" on browser will not work.  If I do not keep this line of code, we will keep adding values in seswsion storage, and may become a memory issue.
            this.request.ApiKey = null;
        }
        this.retrieveContent();
    }

    retrieveContent(): void {
        if (this.request.ContentId === undefined ||
            this.request.ContentId === null ||
            this.request.ContentId.length === 0) {
            window.location.href = window.location.origin + "/error/404";
            return;
        }
        if (this.request.Lang === null || this.request.Lang.length === 0) {
            this.request.Lang = "en";
        }
        this.loading = true;
        this.service.get(this.request).subscribe(a => {
            this.result = a;
            var title = this.$("#m-title", a);

            if (title) {
                this.titleService.setTitle(title.text());
            }
            // check if gnt type to display gnt footer.  type should be in one of the <dd> elements in content-meta-details section
            // go get footer from xml or json
            var ddElements = this.$("#m-content-meta-details", a).find("dd");
            for (var e of ddElements) {
                if (e.innerHTML === "EducationMaterial") {
                    this.contentFooterService.setIsGNTContent(true);
                    this.getGNTFooter();
                    break;
                }
            }

            var dtElements = this.$("#m-content-meta-details", a).find("dt");
            let hasContentType = false;
            for (var e of dtElements) {
                // 7-19-2023: After discussion with Lisa we determined that Composite content types wont have the Content Type attribute. If this changes
                // in future we will need to address this logic. Some content doesnt have this dt element, such as Video.
                if (e.innerHTML === "Content Type") {
                    hasContentType = true;
                    break;
                }
            }
            this.isInteractive = !hasContentType && dtElements.length > 0;

            var copyright = this.$("#m-copyright", a).html();

            if (copyright) {
                this.$("#m-copyright").html(copyright);
            }
            this.$("#m-copyright-container").show();
            var video = this.$("#m-videoId", a).val();
            if (video) {
                var req = new ContentRequestDto();
                req.ContentId = video;
                req.ApiKey = this.request.ApiKey;
                req.SofAppKey = this.request.SofAppKey;
                req.Lang = this.request.Lang;
                this.service.video(req).subscribe(b => {                
                    mayojs.kalturaAutoEmbed("m-video-stream", b.kalturaVideo.videoId);
                    this.hideVideoTranscript();
                    if (title) {
                        this.$("#m-video-stream")
                            .append("<span itemprop='description' content='" + title.text() + "'></span>");
                    }
                    this.$("#m-video-stream").append("<span itemprop='name' content='" + b.kalturaVideo.videoId + "'></span>");
                });
                this.loading = false;
            } else {
                this.loading = false;
            }
            mayojs.loadAnalyticScript(this.request.ContentId);

            if (this.isInteractive) {
                this.addTealium();
            }
        }, err => {
            var code = err.status || err.error.ExceptionMessage || err.error.ResponseMessage || err.error.Message;
            window.location.href = window.location.origin + "/error/" + code;
        });
    }

    hideVideoTranscript(): void {
        //Currently there is a weird issue where the video transcript section is returned within the video-stream section. This causes the video player to be overlayed
        //onto the transcript. Until we resolve that issue we are hard coding the transcript section to be hidden to support how the kaltura player
        //previous replaced the enter video stream section.
        const videoTranscript = document.getElementById('m-video-transcript');
        // Check if the element exists, then hide it
        if (videoTranscript) {
            videoTranscript.style.display = 'none';
        }
    }

    ngAfterViewInit(): void {
        mayojs.init();
    }

    getGNTFooter(): void {
        // if error trying to get gnt footer, then default to the same footer as other content types
        if (this.request.ContentId === undefined ||
            this.request.ContentId === null ||
            this.request.ContentId.length === 0) {
            this.contentFooterService.setIsGNTContent(false);
            return;
        }
        if (this.request.Lang === null || this.request.Lang.length === 0) {
            this.request.Lang = "en";
        }
        this.service.getContentJson(this.request).subscribe(a => {
            var contentJson = JSON.parse(a);
            this.contentFooterService.setGNTFooter(contentJson.footer);
        }, err => {
            this.contentFooterService.setIsGNTContent(false);
        });
    }

    addTealium() {
        let env = '';
        this.utilService.environment().subscribe(res => {
            env = res;
            this.isProd = env.toLowerCase().indexOf('prod') > -1;
            mayojs.loadTealiumScript(this.isProd);
        }, err => {
            this.isProd = false;
            mayojs.loadTealiumScript(this.isProd);
        });
    }
}